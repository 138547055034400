import apios from "../common/apios";

class PubService {
  /* Services methods */
  getRegisteredServices(){
    return apios.get( "pub/getRegisteredServices",{});
  }
  getRegisteredServiceById(sid){
    return apios.get("pub/getRegisteredServiceById", { 
      params: {
        id : sid
      },
      },
    );
  }
  searchProductModel(keyword){
    return apios.get("pub/searchProductModel", {
      params: {
        keyword : keyword
      },
      }
    );
  }
  getProviderById(pid){
    return apios.get("pub/getProviderById", { 
      params: {
        id : pid
      },
      }
    );
  }
}
export default new PubService();
