import React, { useState, useEffect } from "react";
import { Trans, withTranslation } from "react-i18next";
import { ToastContainer, toast, Slide } from "react-toastify";
import { InputText } from "primereact/inputtext";
import WaitDialog from "../components/WaitDialog";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import {
  isLoggedIn,
  isCTAdmin,
  isCTManufacturer,
  isCTProvider,
  isCTRepairer,
} from "../functionalities/common/token-check";
import SysService from "../functionalities/services/sys.service";
import GeneralFunctions from "../functionalities/common/generalFunctions";
import PubService from "../functionalities/services/pub.service"
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { DataTable } from "primereact/datatable";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";

import {
  SearchContainer,
  SearchFilterDialogContainer,
} from "./assets/SearchContainer.style";
import * as Fa6Icons from "react-icons/fa6";
import { CiSearch } from "react-icons/ci";

import { testProductModels } from "../data/ct-data";

const SearchV2 = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchBarPlaceholder, setSearchBarPlaceHolder] = useState(
    t("searchProductModels")
  );
  const [searchResults, setSearchResults] = useState([]);

  const [filterBoxVisibility, setFilterBoxVisibility] = useState(false);
  const [filterBoxExpand, setFilterBoxExpand] = useState(false);

  const navigate = useNavigate();

  const onSearchSubmit = () => {
    if (searchText !== "" || searchText !== null) {
      search();
    }
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      search();
    }
  };
  const search = () => {
    setLoading(true);
    setSearchResults([]);
    searchProductModels();
  }
  const searchProductModels = () => {
    PubService.searchProductModel(searchText).then(
      (response) => {
        setLoading(false);
        console.log(response?.data)
        setSearchResults(response?.data);
      },
      (error) => {
        setLoading(false);
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.responseCode) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();

        toast.error(<Trans i18nKey={errorMessage} />);
      }
    );

  }

  const actionTemplateProductModel = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-chevron-right"
          tooltip={t("inquire")}
          className="p-button-rounded p-button-text"
          onClick={() => {
            // setViewProductModelVisible(true);
            // getProductModelInfo(data?.id);
            // console.log(data)
            // setSingularProductModel(data);
            navigate("/pm/" + data?.id);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };
  const metaDataCatalogueProductModel = (data) => {
    return (
      <div>
        <Button
          icon="pi pi-external-link"
          tooltip={t("goto")}
          className="p-button-rounded p-button-text"
          onClick={() => {

            // navigate("/pm/" + data?.id);
          }}
          id="buttonFontWeight"
        />
      </div>
    );
  };

  useEffect(()=>{
    setSearchResults(testProductModels);
    //search();
  },[])

  return (
    <SearchContainer>
      <h2> {t("lookingFor")}{" : "}{t("productModels")}</h2>

      {/* <div className="search-tabs-container"> */}
        {/* <span className="lookingfor-text-span">
          {t("lookingFor")}
          {" : "}
          {t("productModels")}
        </span> */}
        {/* <Button
          className="search-filter-button"
          label={filterBoxExpand ? t("searchFilters") : ""}
          icon={<Fa6Icons.FaListCheck />}
          onClick={() => setFilterBoxVisibility(true)}
          onMouseEnter={()=>setFilterBoxExpand(true)}
          onMouseLeave={()=>setFilterBoxExpand(false)}
        /> */}
      {/* </div> */}
      <div className="menubar-container">
        {/* <Menubar model={searchBarItems}></Menubar> */}
        <div className="search-bar-container col-12">
          <div className="search-input-container">
            <InputText
              className="search-input"
              placeholder={searchBarPlaceholder}
              // style={{ minWidth: "4rem" }}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="search-filter-button-container">
            <Button
              className="search-filter-button"
              label={filterBoxExpand ? t("searchFilters") : ""}
              icon={<Fa6Icons.FaListCheck />}
              onClick={() => setFilterBoxVisibility(true)}
              onMouseEnter={()=>setFilterBoxExpand(true)}
              onMouseLeave={()=>setFilterBoxExpand(false)}
            />
          </div>

          <div className="search-submit-button-container">
            <Button
              className="search-submit-button"
              style={{}}
              icon={<CiSearch />}
              onClick={() => onSearchSubmit()}
            />
          </div>
        </div>
      </div>
      <div className="search-results-container">
        {/* {searchResults.length >= 0 && ( */}
        <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
          <DataTable
            size="small"
            emptyMessage={t("noSearchResults")}
            showGridlines
            value={searchResults}
            paginator
            responsiveLayout="scroll"
            rows={30}
            loading={loading}
          >
            {/* <Column
              field="manufacturer "
              header={t("productModelManufacturer")}
            /> */}
            <Column
              field="sector"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelSector")}
            />
            <Column
              field="productCategory"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelCategory")}
            />
            <Column
              field="productType"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelType")}
            />
            <Column
              field="brandName"
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelBrandName")}
            />
            {/* <Column
              field="country"
              header={t("productModelCountry")}
            /> */}
            <Column 
              field="name" 
              style={{ textAlign: "center", width: "6em" }}
              header={t("productModelName")}
            />
            <Column
              header={t("productmodelActions")}
              style={{ textAlign: "center", width: "6em" }}
              body={actionTemplateProductModel}
            />
            <Column
              header={t("productmodelMetaDataCatalogueActions")}
              style={{ textAlign: "center", width: "6em" }}
              body={metaDataCatalogueProductModel}
            />
          </DataTable>
          </div>
        </div>
      </BlockUI>
        {/* )} */}
      </div>
      <Dialog
        visible={filterBoxVisibility}
        modal
        header={t("searchFilters")}
        className="p-fluid dialog-size"
        onHide={() => {
          setFilterBoxVisibility(false);
        }}
      >
        <SearchFilterDialogContainer>
          {/* <div>b</div> */}
        </SearchFilterDialogContainer>
      </Dialog>

      <WaitDialog loading={loading} lang={t} />

      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </SearchContainer>
  );
};
export default withTranslation()(SearchV2);
