import { AllServicesContainer } from './assets/ServicesContainer.style'
import { ServicesCardsSection } from './assets/ServicesCardsSection'
import { withTranslation } from 'react-i18next';
import WaitDialog from "../components/WaitDialog";
import React, { useEffect, useState } from "react";
import ServicesCards from "../components/ServicesCards"
import PubService from "../functionalities/services/pub.service" 
import SysService from "../functionalities/services/sys.service";
import { isLoggedIn } from '../functionalities/common/token-check';
import { ToastContainer, toast, Slide } from "react-toastify";

const Services = ({t}) => {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);

  useEffect(() => {

    if(isLoggedIn()){
      SysService.getRegisteredServices().then(
        (response) => {
         
          setServices(response.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();
  
      
          toast.error(error.response.status + " " + error.response.data.responseMessage + " " +error.name)

        }
      );
    }
    else{
      PubService.getRegisteredServices().then(
        (response) => {
        
          setServices(response.data);
        },
        (error) => {
          const errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.timeoutErrorMessage ||
            error.toString();

          toast.error(error.response.status + " " + error.response.data.responseMessage + " " +error.name)

        }
      );
    }
  }, []);
    return (
      <AllServicesContainer>
          <h2>{t("availableServices")}</h2>
          {t("serviceslistmsg")}  
          <ServicesCardsSection>
            <div className='description'>
               
            </div>
              {services.length > 0 ? (
                <div className='service-card-container'>
                    {services?.map((item, index) => (
                      <ServicesCards
                        item={item}
                        key={index}
                      />
                    ))}
                </div>
              ):(
                <div className='no-services'>{t("noServicesFound")}</div>
              )}

          </ServicesCardsSection>

          <WaitDialog loading={loading} lang={t} />
          <ToastContainer
            autoClose={2200}
            position={toast.POSITION.BOTTOM_RIGHT}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick={true}
            rtl={false}
            theme="dark"
            pauseOnFocusLoss={true}
            draggable={false}
            pauseOnHover={true}
            transition= {Slide}
          />
      </AllServicesContainer>
    )
  }
  
  export default withTranslation()(Services);