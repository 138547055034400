import "./styles/App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { PrivateRoute, PublicRoute } from "./functionalities/common/routeCheck";
import WaitDialog from "./components/WaitDialog";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import PageNotFound from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import CookiesPolicy from "./pages/CookiesPolicy";

import Services from "./pages/Services";
import Organisations from "./pages/Organisations";
import Providers from "./pages/Providers";
import ProductModels from "./pages/ProductModels";

import CTPostLoginLanding from "./pages/CTPostLoginLanding";
import CTHome from "./pages/CTHome";
import Login from "./pages/Login";
import EditServices from "./pages/EditServices";
import EditRoles from "./pages/EditRoles";
import EditProfile from "./pages/EditProfile";
import { isLoggedIn } from "./functionalities/common/token-check";
import LeftBar from "./components/LeftBar";
import Search from "./pages/Searchv2";
import APIDoc from "./pages/Api";
import ProductModelView from "./pages/ProductModelView";

const App = ({ t }) => {
  const [loading, setLoading] = useState(false);
  return (
    <BrowserRouter>
      <Navbar className="app-nav" />

      <div className="app-container">
        {isLoggedIn() && (
          <div className="left-bar">
            <LeftBar />
          </div>
        )}

        <div className="app-wrapper">
          <div className="app-content">
            <Routes>
              <Route path="/" element={<CTHome />} restricted={false} exact />
              <Route
                path="/login"
                element={<Login />}
                restricted={false}
                exact
              />
              <Route
                path="/PrivacyPolicy"
                element={
                  <PublicRoute restricted={false} component={PrivacyPolicy} />
                }
                exact
              />
              <Route
                path="/TermsAndConditions"
                element={
                  <PublicRoute
                    restricted={false}
                    component={TermsAndConditions}
                  />
                }
                exact
              />
              <Route
                path="/CookiesPolicy"
                element={
                  <PublicRoute restricted={false} component={CookiesPolicy} />
                }
                exact
              />
              <Route
                path="/allServices"
                element={
                  <PublicRoute restricted={false} component={Services} />
                }
                exact
              />
              <Route
                path="/search"
                element={
                  <PublicRoute restricted={false} component={Search} />
                }
                exact
              />
              <Route
                path="/pm/:id"
                element={
                  <PublicRoute restricted={false} component={ProductModelView} />
                }
                exact
              />
              <Route
                path="/apiDocumentation"
                element={
                  <PublicRoute restricted={false} component={APIDoc} />
                }
                exact
              />
              <Route
                path="/manageServices"
                element={
                  <PrivateRoute restricted={false} component={EditServices} />
                }
                exact
              />
              <Route
                path="/manageRoles"
                element={
                  <PrivateRoute restricted={false} component={EditRoles} />
                }
                exact
              />
              <Route
                path="/allOrganisations"
                element={
                  <PrivateRoute restricted={false} component={Organisations} />
                }
                exact
              />
              <Route
                path="/allProductModels"
                element={
                  <PrivateRoute restricted={false} component={ProductModels} />
                }
                exact
              />
              <Route
                path="/allProviders"
                element={
                  <PrivateRoute restricted={false} component={Providers} />
                }
                exact
              />
              <Route
                path="/landing"
                element={
                  <PrivateRoute
                    restricted={false}
                    component={CTPostLoginLanding}
                  />
                }
                exact
              />
              <Route
                path="/editProfile"
                element={
                  <PrivateRoute restricted={false} component={EditProfile} />
                }
              />
              <Route
                path="*"
                element={
                  <PublicRoute restricted={true} component={PageNotFound} />
                }
                exact
              />
            </Routes>
            <WaitDialog loading={loading} lang={t} />
          </div>
        </div>
      </div>

      <Footer />
    </BrowserRouter>
  );
};

export default withTranslation()(App);
