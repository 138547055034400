import styled from "@emotion/styled";
export const ProductModelsContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align:left;

  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }

  .backToHomeBtn {
    background-color: #fff;
    color: #00a3ff;
  }
`;

export const AddProductModelContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  //gap: 1rem;
  //background-color: #fff;
  //border-radius: 6px;
  flex-shrink: 0;
  //box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: #595959;
  width: 100%;
  align-items: flex-start;
  padding: 0rem;
  text-align: left;


  .view-button {
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }  }

  .view-button-disable {
   cursor: default !important; 
  }

  .join-button {
    background-color: #116182;
  }

  .text-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 13px;
  }

  .text-container * {
    font-size: 13px;
  }

  .colored-text1 {
    color: #116182;
  }
  .colored-text2 {
    color: #f1812f;
  }
`;
export const EditProductModelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    

    .input-container{
      width: 100%;
      margin-bottom: 1.5rem;
      
      .platformColor{
        color: #00a3ff;
      }
    }
    .submit-button {
      background-color: #00a3ff;
      border-radius: 6px;
      border-style: solid;
      border-color: #00a3ff;
      border-width: thin;
      &:hover {
        background-color: #f72685;
        border-radius: 6px;
        border-style: solid;
        border-color: #f72685;
        border-width: thin;
      }
    }   

`;

export const ViewProductModelContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
   
    .input-container{
      width: 100%;
      margin-bottom: 1.5rem;
      
      .platformColor{
        color: #00a3ff;
      }
      .view-container{
        color: #382c7c;
        width: 50%;
      }
      .chip-title{
        color: #00a3ff;
        font-size: 13px;
      }
      .chip-holder{
        margin-top: 1rem;
      }
      .platformColor-roles{
        color: #382c7c;
        margin-top: 1rem;
      }  


    }
    .search-item-info-container{
      width: 100%;
      margin-bottom: 1.5rem;
      
      .platformColor{
        color: #00a3ff;
      }
      .search-pm-image > img{
          max-width: 320px !important;
          max-height: 240px !important;
      }
    }

`;
