import styled from "@emotion/styled";
export const ApiDocContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;

  .api-doc-main-container{
    display:flex; 
    flex-direction: row; 
    justify-content: center; 
    // align-items: center;
    // margin:auto;
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .api-doc-main-container > div {
    gap: 2rem;
    margin:auto;
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .api-doc-left-menu-container{
    max-width: 450px;
    align: left;
    text-align: left;

  }
  .api-doc-content-container{

  }
  .api-doc-requests-container{

  }



  .toSwaggerBtn {
    
    background-color: #382c7c;
    border-radius: 6px;
    border-style: solid;
    border-color: #382c7c;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;