import styled from "@emotion/styled";
export const ProductModelViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    max-width: 75%;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: #fff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    flex-shrink: 0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    // color: #00a3ff;
    align-items: center;
    text-align:center;
    .search-item-info-div{
      border-radius: 2px;
      border-style: solid;
      border-color: #00a3ff;
      border-width: thin;
      flex-shrink: 0;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      margin-bottom: 1.5rem;
      margin-left : 1rem;
      margin-right: 1rem;
    }

    .search-item-info-container{
      width: 100%;
      margin-bottom: 1.5rem;
      .platformColor-head{
        color: #00a3ff;
      }
      .platformColor{
        color: #00a3ff;
      }
      .search-item-services-btn{
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover {
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
      .search-pm-image-link{
        font-size : 12px;
      }
      .search-pm-image > img{
          max-width: 320px !important;
          max-height: 240px !important;
      }
    }

  @media (max-width: 768px){
    .search-item-info-container{
      width: 100%;
      margin-bottom: 1.5rem;

      .search-pm-image > img{
          max-width: 320px !important;
          max-height: 240px !important;
      }
    }
  }
  @media (max-width: 576px) {
    .search-item-info-container{
      width: 100%;
      margin-bottom: 1.5rem;

      .search-pm-image > img{
          max-width: 280px !important;
          max-height: 210px !important;
      }
    }
  }
  @media (max-width: 350px) {
    .search-item-info-container{
      width: 100%;
      margin-bottom: 1.5rem;

      .search-pm-image > img{
          max-width: 240px !important;
          max-height: 180px !important;
      }
    }
  }

`;
export const DPPViewContainer = styled.div`
  gap: 1rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: center;
  text-align:center;
  .dpp-view-container{

  }
  .dpp-view-inputarea{
    max-width: 50%;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
  }
  .dpp-view-submit-btn{
    max-width: 30%;
    text-align:center;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
  .dpp-view-btn{
    max-width: 65%;
    text-align:center;
    margin-bottom: 0.25rem;
    margin-top: 0.25rem;
    background-color: #00a3ff;
    border-radius: 6px;
    border-style: solid;
    border-color: #00a3ff;
    border-width: thin;
    &:hover {
      background-color: #f72685;
      border-radius: 6px;
      border-style: solid;
      border-color: #f72685;
      border-width: thin;
    }
  }
`;
export const AvailableServicesContainer = styled.div`

`;
