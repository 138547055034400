import styled from "@emotion/styled";
export const RolesContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: #fff;
  border-radius: 6px;
  border-style: solid;
  border-color: #00a3ff;
  border-width: thin;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #00a3ff;
  text-align:left;
  /* margin-left: 3.75rem; */


  .description {
    font-size: 15px;
    line-height: 20px;
    font-weight: 800;
  }
  .service-card-container {
    display: grid;
    gap: 1rem;
    width: 100%;
    padding: 1.5rem;
    grid-template-columns: repeat(4, 1fr); 
}
  .role-dt{
    // border-radius: 1px;
    // border-style: solid;
    // border-color: #00a3ff;
  }

  @media (max-width: 1200px) {
    .service-card-container {
        grid-template-columns: repeat(3, 1fr); 
    }
}

@media (max-width: 900px) {
    .service-card-container {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media (max-width: 600px) {
    .service-card-container {
        grid-template-columns: repeat(1, 1fr); 
    }
}
`;


export const CreateRolesDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
    

    .input-container{
      width: 100%;
      margin-bottom: 1.5rem;
      
      .platformColor{
        // color: #f72685;
        // color: #382c7c;
        color: #00a3ff;
        
      }
      
    }
      .submit-button{
        background-color: #00a3ff;
        border-radius: 6px;
        border-style: solid;
        border-color: #00a3ff;
        border-width: thin;
        &:hover{
          background-color: #f72685;
          border-radius: 6px;
          border-style: solid;
          border-color: #f72685;
          border-width: thin;
        }
      }
`;
export const MatchRolesDialogContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem 0 1rem;
`;