export const serviceTypesList = [
    {"serviceTypeName": "IFRAME", "serviceTypeCode" : 1},
    {"serviceTypeName": "API", "serviceTypeCode" : 2},
];
export const providerTypesList = [
    // {"providerTypeName" : "Dpo", "providerTypeCode": 1},
    {"providerTypeName" : "Dpp", "providerTypeCode": 2},
];
export const statusTypesList = [
    // {"statusTypeName": "PENDING", "statusTypeCode" : 1},
    {"statusTypeName": "ACTIVE", "statusTypeCode" : 2},
    {"statusTypeName": "DISABLED", "statusTypeCode" : 3},
];
export const testProductModels = [
    {"name": "a", "brandName" : "a", "description":"a", "ean":"", "imageUri" :"https://www.elityavru.com/images/irk-bilgileri/kopek-irki/pembroke-welsh-corgi.webp", "id" :"1"},
    {"name": "b", "brandName" : "b", "description":"b", "imageUri" :"https://i.ytimg.com/vi/6PQxJkLqc6c/sddefault.jpg","id" :"2"}

];