/* 
if endpoint is /api_documentation change (could be developer.ct or api.ct)
the leftbar and adjust contents accordingly

https://developer.revolut.com/docs/business/business-api
*/

import React, { useEffect, useState } from "react";
import { ApiDocContainer } from "./assets/ApiDocContainer.style";
import { withTranslation } from "react-i18next";
import { Button } from "primereact/button";
import { NavLink } from "react-router-dom";
import { Chip } from "primereact/chip";
import config from "../config";
import APIService from "../functionalities/services/api.service";

const Api = ({ t }) => {
  const [apiJson, setApiJson] = useState({});
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(0);
  const [dtos, setDtos] = useState({});
  const [paths, setPaths] = useState({});
  const [apiMenuItems, setApiMenuItems] = useState({});

  const menuContents = (paths) => {
    var _menu = [];
    var _;
  };

  const handlePath = (path) => {
    if (path.includes("/auth/")) {
    } else if (path.includes("/ProductModel/")) {
    } else if (path.includes("/RegisteredService/")) {
    }
  };

  const handlePathMethod = (path) => {
    // console.log(path)
    if ("get" in path) {
      return (
        <div>
          <Chip
            label={"GET"}
            className="method-chip"
            style={{
              backgroundColor: "#61affe",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
            }}
          />{" "}
          {path["get"]?.operationId}
        </div>
      );
    } else if ("post" in path) {
      return (
        <div>
          <Chip
            label={"POST"}
            className="method-chip"
            style={{
              backgroundColor: "#49cc90",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
            }}
          />
          {path["post"]?.operationId}
        </div>
      );
    } else if ("put" in path) {
      return (
        <div>
          <Chip
            label={"PUT"}
            className="method-chip"
            style={{
              backgroundColor: "#fca130",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
            }}
          />
          {path["put"]?.operationId}
        </div>
      );
    } else if ("delete" in path) {
      return (
        <div>
          <Chip
            label={"DELETE"}
            className="method-chip"
            style={{
              backgroundColor: "#f93e3e",
              marginRight: "0.7rem",
              marginBottom: "0.7rem",
            }}
          />
          {path["delete"]?.operationId}
        </div>
      );
    }
  };

  useEffect(() => {
    // process.env kullanmak yerine
    // setApiJson(getAPIEndPoints(window.location.origin))
    //setApiJson(APIService.getAPIEndPoints(config.backendApiUrl))
    APIService.getAPIEndPoints(config.backendApiUrl).then(
      (response) => {
        setApiJson(response?.data);
      },
      (error) => {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.timeoutErrorMessage ||
          error.toString();
      }
    );
  }, []);
  useEffect(() => {
    setDtos(apiJson?.components?.schemas);
    setPaths(apiJson?.paths);
  }, [apiJson]);
  useEffect(() => {
    if (paths != null || paths !== undefined) {
      const _paths = Object.keys(paths);
      console.log(_paths);
      let _menu = {};
      let _menuAuth = [];
      let _menuProductModel = [];
      let _menuRegisteredServices = [];
      // _menuAuth.push(<div>{t("Information")}</div>);
      // _menuProductModel.push(<div>{t("Information")}</div>);
      // _menuRegisteredServices.push(<div>{t("Information")}</div>);
      for (let i = 0; i < _paths.length; i++) {
        if (_paths[i].includes("api/auth")) {
          // _menuAuth.push(_paths[i]);
          _menuAuth.push(handlePathMethod(paths[_paths[i]]));
        } else if (_paths[i].includes("ProductModel")) {
          // _menuProductModel.push(_paths[i]);
          _menuProductModel.push(handlePathMethod(paths[_paths[i]]));
        } else if (_paths[i].includes("RegisteredService")) {
          // _menuRegisteredServices.push(_paths[i]);
          _menuRegisteredServices.push(handlePathMethod(paths[_paths[i]]));
        }
      }
      _menu["auth"] = _menuAuth;
      _menu["productModel"] = _menuProductModel;
      _menu["registeredService"] = _menuRegisteredServices;

      // for(let i = 0; i<_paths.length;i++){
      //   //console.log(paths[_paths[i]])
      //   if(_paths[i].includes("ProductModel")){
      //     console.log(_paths[i])
      //   }
      //   _menu[i] = handlePathMethod(paths[_paths[i]])

      // }
      setApiMenuItems(_menu);
    }
  }, [paths]);
  console.log(apiMenuItems);
  return (
    <ApiDocContainer>
      <h2>{t("APIDocumentationPage")}</h2>

      <div className="api-doc-main-container">
        <div className="api-doc-left-menu-container">
          {/* {apiMenuItems.length>0 ? (
              apiMenuItems.map((i)=> (
                <div className='api-doc-left-menu-item'>{i}</div>
              ))

            ) : (
              <div>{t("noPathsAvailable")}</div>
            )} */}
          {/* {Object.keys(apiMenuItems).length > 0 ? (
            // <div>{apiMenuItems.auth}</div>
            Object.keys(apiMenuItems).map((menuItem)=>{
              <div>{apiMenuItems.auth}</div>
            })
          ) : (
            <div>{t("noPathsAvailable")}</div>
          )} */}
          <div className='api-doc-left-menu-item'>{apiMenuItems.auth}</div>
          <div className='api-doc-left-menu-item'>{apiMenuItems.productModel}</div>
          <div className='api-doc-left-menu-item'>{apiMenuItems.registeredService}</div>
        </div>
        {/* <div className="api-doc-content-container">b</div> */}
        {/* <div className='api-doc-requests-container'>c</div> */}
      </div>

      {/* <div className='header-container'>
        </div>

        <div className='description'>
        </div> */}
      <div className="swagger-info-text-container">{t("swaggerInfoText")}</div>
      <NavLink
        to={config.backendApiUrl + "/api/swagger-ui/index.html#/"}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Button label={t("goToSwaggerPage")} className="toSwaggerBtn" />
      </NavLink>
    </ApiDocContainer>
  );
};

export default withTranslation()(Api);
