import { RolesContainer,CreateRolesDialogContainer,MatchRolesDialogContainer } from "./assets/RolesContainer.style";
import {
  OrganisationAddContainer,
} from "./assets/OrganisationsContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Column } from "primereact/column";
import { BlockUI } from "primereact/blockui";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import SysService from "../functionalities/services/sys.service";

const emptyRoleData = {
  nameInput : "",
  name: "",
};

const EditRoles = ({ t }) => {
  const [loading, setLoading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [services, setServices] = useState([]);
  const [roleData, setRoleData] = useState(emptyRoleData);
  const [createRoleVisible, setCreateRoleVisible] = useState(false);
  const [matchRoleVisible, setMatchRoleVisible] = useState(false);
  const [roleMap, setRoleMap] = useState({});

  const submitRoleCreation = () => {
    setLoading(true);

    if ( roleData.name === "" ) {
      setLoading(false);
      toast.warning(<Trans i18nKey="warnMandatory" />);

      return;
    }
    if( /\s/g.test(roleData.name)){
      toast.warning(<Trans i18nKey="warnNoSpacesInRoles" />);
      setLoading(false);
      return;
    }
    const roleDTO = {
      roleDTO: "CT_"+roleData.name,
    }
  

    setLoading(false);
    return;
  };
  const handleRoleMatchToService = () => {

  };


  const handleSubmit = (e) => {
    e.preventDefault();
  };

  useEffect(()=>{
    SysService.getRoles().then(
      (response) => {
 
        setRoles(response.data);
      },
      (error) => {
        const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.timeoutErrorMessage ||
        error.toString();
      }
    );
    SysService.getRegisteredServices().then(
      (response) => {
 
        setServices(response.data);
      },
      (error) => {
        const errorMessage =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.timeoutErrorMessage ||
        error.toString();
      }
    );
  },[]);
  console.log(services)
  return (
    <RolesContainer>
      <h2>{t("rolesPage")}</h2>
      {/* <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("createARole")}
          onClick={() => setCreateRoleVisible(true)}
        />
      </OrganisationAddContainer> */}
      <OrganisationAddContainer>
        <Button
          className="view-button"
          label={t("matchRolesToServices")}
          onClick={() => setMatchRoleVisible(true)}
        />
      </OrganisationAddContainer>
      <BlockUI fullscreen autoZIndex={false}>
        <div className="col-12">
          <div className="surface-0 shadow-2 p-3 border-1 border-50 border-round">
            <DataTable
              name="dt"
              size="small"
              showGridlines
              value={roles}
              className="role-dt"
              dataKey="id"
              responsiveLayout="scroll"
              emptyMessage={t("noRolesFound")}
              // header={renderHeader1}
              paginator
              rows={10}
              // filters={filters1}
              // filterDisplay="menu"
              loading={loading}
              style={{backgroundColor:"#EBEBEB"}}
              // globalFilterFields={["serviceName", "serviceContact", "status"]}
            >
              <Column
                field="name"
                header={t("roleName")}
                // sortable
              ></Column>

              {/* <Column
                    header={t("action")}
                    style={{ textAlign: "center", width: "8em" }}
                    body={actionTemplate}
                  /> */}
            </DataTable>
          </div>
        </div>
      </BlockUI>
      <Dialog
        visible={createRoleVisible}
        modal
        header={t("createARole")}
        className="p-fluid dialog-size"
        onHide={() => {
          setCreateRoleVisible(false);
          setRoleData(emptyRoleData);
        }}
      >
        <CreateRolesDialogContainer>
          {/* <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem"
              // letterSpacing: "0.5px",
            }}
          >
            {t("createARole")}
          </div> */}
          <form onSubmit={handleSubmit}>
            <div className="flex align-items-center gap-3 ">
              <div className="p-float-label input-container">
                <InputText
                  id="serviceName"
                  type="text"
                  className="p-inputtext-sm w-full"
                  value={roleData?.name}
                  onChange={
                    // (e) => handleOrgCreation("organisationName", e.target.value)
                    (e) => setRoleData({ ...roleData, name: (e.target.value).toUpperCase() })
                  }
                />
                <label className="platformColor" htmlFor="serviceName">
                  {t("pleaseEnterRoleName")} (*)
                </label>
              </div>
              <div>
                <p>{t("roleInfoText")} {"CT_"}{roleData.name}</p>
              </div>
            </div>
          </form>
          <Button
            className="submit-button"
            label={t("submit")}
            onClick={submitRoleCreation}
          />
        </CreateRolesDialogContainer>
      </Dialog>
      <Dialog
        visible={matchRoleVisible}
        modal
        header={t("matchRolesToServices")}
        className="p-fluid dialog size"
        onHide={() => {
          setMatchRoleVisible(false);

        }}
      >
        <MatchRolesDialogContainer>
          <div className="">
            <MultiSelect
              id="serviceList"
              className="w-full p-inputtext-sm service-container"
              optionLabel="name"
              value={services.name}
              options={services}
              display="chip"
              // onChange={(e) =>
              //   setOrgData({
              //     ...orgData,
              //     roles: e.value,
              //   })
              // }
            />
          </div>
          <div className="">
            <MultiSelect
                id="serviceList"
                className="w-full p-inputtext-sm service-container"
                optionLabel="name"
                value={roles}
                options={roles}
                display="chip"
                // onChange={(e) =>
                //   setOrgData({
                //     ...orgData,
                //     roles: e.value,
                //   })
                // }
            />
          </div>
          <Button
            className="submit-button"
            label={t("submit-wip")}
            disabled
            onClick={handleRoleMatchToService}
          />
          
        </MatchRolesDialogContainer>
      </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </RolesContainer>
  );
};

export default withTranslation()(EditRoles);
