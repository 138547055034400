import { ProductModelViewContainer,DPPViewContainer,AvailableServicesContainer } from "./assets/ProductModelViewContainer.style";
import { withTranslation, Trans } from "react-i18next";
import { Button } from "primereact/button";
import WaitDialog from "../components/WaitDialog";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast, Slide } from "react-toastify";
import { Dialog } from "primereact/dialog";
import SysService from "../functionalities/services/sys.service";
import PubService from "../functionalities/services/pub.service";
import { redirect, useParams } from "react-router";
import { isLoggedIn, isCTAdmin, isCTManufacturer, isCTProvider, isCTRepairer } from "../functionalities/common/token-check";
import { Image } from 'primereact/image';
import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";

const ProductModelView = ({ t }) => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [viewAvailableServices, setViewAvailableService] = useState(false);
  const [viewDPPOptions, setViewDPPOptions] = useState(false);
  const [productModelData, setProductModelData] = useState({});

  const roleTemplate = () => {
    const roles = [
      (isCTAdmin() && (<Chip label={t("ct_admin")} className="mr-2 mb-2" style={{ backgroundColor: "#382c7c", color: "#FFFFFF" }} />)),
      (isCTManufacturer() && (<Chip label={t("manufacturer")} className="mr-2 mb-2" style={{ backgroundColor: "#382c7c", color: "#FFFFFF" }} />)),
      (isCTProvider() && (<Chip label={t("provider")} className="mr-2 mb-2" style={{ backgroundColor: "#382c7c", color: "#FFFFFF" }} />)),
      (isCTRepairer() && (<Chip label={t("repairer")} className="mr-2 mb-2" style={{ backgroundColor: "#382c7c", color: "#FFFFFF" }} />)),
    ]
    return roles; 
  }
  const redirectToMDC = () => {
    console.log("mdc_redirect")
    setLoading(true);
  }

  useEffect(()=>{
    if(isLoggedIn()){
        if(isCTManufacturer()){
          setLoading(true);
          SysService.getProductModelById(id).then(
              (response) => {
                setLoading(false);
        
                setProductModelData(response.data);
              },
              (error) => {
                const errorMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.timeoutErrorMessage ||
                error.toString();
        
              setLoading(false);
              }
            );
        }
        else{
          setProductModelData("You are not manufacturer!")

        }
    }
    else{
      //setProductModelData("You are not logged in!")
      setLoading(true);
      // PubService.getProductModelById(id).then(
      //     (response) => {
      //       setLoading(false);
    
      //       setProductModelData(response.data);
      //     },
      //     (error) => {
      //       const errorMessage =
      //       (error.response &&
      //         error.response.data &&
      //         error.response.data.message) ||
      //       error.message ||
      //       error.timeoutErrorMessage ||
      //       error.toString();
    
      //     setLoading(false);
      //     }
      //   );
    }
   
  },[]);

  return (
    <ProductModelViewContainer>

          <div
            className="px-2 platformColor"
            id="dialogTitle"
            style={{
              fontSize: "16px",
              lineHeight: "18px",
              fontWeight: "700",
              marginBottom: "0.5rem",
            }}
          >
          </div>
            <div className="flex align-items-center gap-3 ">

              {(productModelData?.imageUri !== "" && productModelData?.imageUri !== null && productModelData?.imageUri !== undefined) && (
                <div className="p-float-label search-item-info-container">
                  <p className="platformColor">{t("productModelImageURI")}{":"} </p>
                  <p className="search-pm-image-link">{productModelData?.imageUri}</p>
                  <Image className="search-pm-image"  src={productModelData?.imageUri} ></Image>
                </div>
              )}


              {isLoggedIn() && (
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("yourRoles")}{":"}</span> {roleTemplate()}</p>
                </div>


              )}

              <div className="p-float-label search-item-info-div">
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelSector")}{":"}</span> {productModelData?.sector}</p>
                </div>
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelCategory")}{":"}</span> {productModelData?.productCategory}</p>
                </div>
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelType")}{":"}</span> {productModelData?.productType}</p>
                </div>
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelName")}{":"}</span> {productModelData?.name}</p>
                </div>
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelBrandName")}{":"}</span> {productModelData?.brandName}</p>
                </div>
                {(productModelData?.ean !== "" && productModelData?.ean !== null && productModelData?.ean !== undefined) && (
                  <div className="p-float-label search-item-info-container">
                    <p> <span className="platformColor">{t("productModelEan")}{":"}</span> {productModelData?.ean}</p>
                  </div>
                )}
                {(productModelData?.mpn !== "" && productModelData?.mpn !== null && productModelData?.mpn !== undefined) && (
                  <div className="p-float-label search-item-info-container">
                    <p> <span className="platformColor">{t("productModelMpn")}{":"}</span> {productModelData?.mpn}</p>
                  </div>
                )}
                {(productModelData?.code !== "" && productModelData?.code !== null && productModelData?.code !== undefined) && (
                  <div className="p-float-label search-item-info-container">
                    <p> <span className="platformColor">{t("productModelCode")}{":"}</span> {productModelData?.code}</p>
                  </div>
                )}
                {(productModelData?.gtin !== "" && productModelData?.gtin !== null && productModelData?.gtin !== undefined) && (
                  <div className="p-float-label search-item-info-container">
                    <p> <span className="platformColor">{t("productModelGtin")}{":"}</span> {productModelData?.gtin}</p>
                  </div>
                )}
                {(productModelData?.description !== "" && productModelData?.description !== null && productModelData?.description !== undefined) && (
                  <div className="p-float-label search-item-info-container">
                    <p className="platformColor">{t("productModelDescription")}{":"}</p>
                    <p className="">{productModelData?.description}</p>
                  </div>
                )}


              </div>
              <div className="p-float-label search-item-info-div">
              <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelProvider")}{":"}</span> {productModelData?.dppProvider}</p>
                </div>
                <div className="p-float-label search-item-info-container">
                  <p> <span className="platformColor">{t("productModelProviderCreationDate")}{":"}</span> {productModelData?.dppProviderCreationDate}</p>
                </div>
              </div>





              {/* <div className="p-float-label input-container">
                <p className="chip-title">{t("productModelStatus")}</p>
                {actionTemplateStatus(productModelData)}
              </div> */}
              <div className="p-float-label search-item-info-container">
                {/* <p className="chip-title">{t("availableServices")}</p> */}
                <Button 
                  className="search-item-services-btn"
                  onClick={()=>{setViewDPPOptions(true)}}
                >
                  {"overallDPP"}
                </Button>
              </div> 
              <div className="p-float-label search-item-info-container">
                {/* <p className="chip-title">{t("availableServices")}</p> */}
                <Button 
                  className="search-item-services-btn"
                  onClick={()=>{redirectToMDC();}}
                >
                  {"metaDataCatalogue"}
                </Button>
              </div>
              <div className="p-float-label search-item-info-container">
                {/* <p className="chip-title">{t("availableServices")}</p> */}
                <Button 
                  className="search-item-services-btn"
                  onClick={()=>{setViewAvailableService(true)}}
                >
                  {"availableServices"}
                </Button>
              </div>
              {/* <div>
                {productModelData?.documents?.length > 0 && (
                  <DataTable
                    name="dt"
                    size="small"
                    showGridlines
                    value={productModelData?.documents}
                    dataKey="id"
                    responsiveLayout="scroll"
                    emptyMessage={t("noProductModelDocumentsFound")}
                    paginator
                    rows={5}
                    loading={loading}
                  >
                    <Column
                      field="artifactTitle"
                      header={t("productModelDocumentArtifactTitle")}
                    ></Column>
                    <Column
                      field="artifactPublicUrl"
                      header={t("productModelDocumentArtifactPublicUrl")}
                    ></Column>
                    <Column
                      field="artifactLocalUrl"
                      header={t("productModelDocumentArtifactLocalUrl")}
                    ></Column>
                    <Column
                      field="description"
                      header={t("productModelDocumentDescription")}
                    ></Column>
                    <Column
                      field={documentKeywordTemplate}
                      header={t("productModelDocumentKeyword")}
                    ></Column>
                    <Column
                      field={documentCreationtimeTemplate}
                      header={t("productModelCreationTime")}
                    ></Column>
                  </DataTable>
                )}
              </div> */}
            </div>
          <Dialog
            visible={viewDPPOptions}
            modal
            header={t("overallDPP")}
            className="p-fluid dialog-size"
            onHide={() => {
              setViewDPPOptions(false);
            }}
            style={{width:"550px"}}
          >
            <DPPViewContainer>
              <div
                className="dpp-view-container"
              >
                <div className="view-dpp-btn-container">
                  <Button 
                    className="dpp-view-btn"
                    onClick={()=>{}}
                  >
                    {"viewModelLevelDPP"}
                  </Button>
                </div>
                <div className="or-container"><p className="or-text">{t("or")}</p></div>
                <div className="enter-dpp-info-btn-container">
                  <InputText className="dpp-view-inputarea"></InputText>
                  <Button 
                    className="dpp-view-submit-btn"
                    onClick={()=>{}}
                  >
                    {"enterItemIdentifier"}
                  </Button>
                  <InputText className="dpp-view-inputarea"></InputText>
                  <Button 
                    className="dpp-view-submit-btn"
                    onClick={()=>{}}
                  >
                    {"enterBatchId"}
                  </Button>
                </div>
              </div>
            </DPPViewContainer>

          </Dialog>
          <Dialog
            visible={viewAvailableServices}
            modal
            header={t("availableServices")}
            className="p-fluid dialog-size"
            onHide={() => {
              setViewAvailableService(false);
            }}
          >
            <AvailableServicesContainer>

            </AvailableServicesContainer>

          </Dialog>
      <WaitDialog loading={loading} lang={t} />
      <ToastContainer
        autoClose={2200}
        position={toast.POSITION.BOTTOM_RIGHT}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        theme="dark"
        pauseOnFocusLoss={true}
        draggable={false}
        pauseOnHover={true}
        transition={Slide}
      />
    </ProductModelViewContainer>
  );
};

export default withTranslation()(ProductModelView);
